import { FC, useEffect, useState } from "react";
import { post } from "services/wrappers";
import IField from "types/IField";
import IFormConfig from "types/IFormConfig";

import { EFieldName } from "../../configurations/EFieldType";

interface InfoSalon {
  Id: string;
  Name: string;
  Address: string;
  Phone: string;
}

interface InfoContact {
  Id: string;
  Title: string;
  Content: string;
}

interface Salon {
  InfoSalons: InfoSalon[];
  InfoContacts: InfoContact[];
}

export interface Props {
  formCode: string;
  formConfig: IFormConfig;
  countryCode: string;
}

// const getCountryTitle = (
//   internalCountryId: string,
//   internalCountryName: string
// ) =>
//   internalCountryId === "AT"
//     ? "Kontakt in Österreich"
//     : `STUDIOS IN ${internalCountryName}`;

const SalonBox: FC<Props> = function ({ formCode, formConfig, countryCode }) {
  const [internalCountryId, setInternalCountryId] = useState<string>("");
  const [internalCountryName, setInternalCountryName] = useState<string>("");
  const [responseData, setResponseData] = useState<Salon[]>([]);

  useEffect(() => {
    const field: IField[] = formConfig.Fields;
    const countryId = field.find(
      (fieldElement) => fieldElement.Name === EFieldName.CountrySelector
    )?.Value;

    if (countryId !== undefined && countryId !== internalCountryId) {
      const countries = field.find(
        (fieldElement) => fieldElement.Name === EFieldName.CountrySelector
      )?.Options;
      const countryName =
        countries?.find((country) => country.Name === countryId)?.Label || "";
      const location = document.documentElement.lang.replace("-", "_");
      const data = {
        FormId: formCode,
        LanguageId: location,
        CountryCode: countryId,
      };

      setInternalCountryId(countryId);
      setInternalCountryName(countryName.toUpperCase());

      post(`${process.env.REACT_APP_GSDB_API}/Form/GetInfo`, data).then(
        (response) => setResponseData(response.Data)
      );
    }
  }, [formCode, formConfig, countryCode]);

  const salonTitle = (cid: string, countryName: string) => {
    if (cid === "ES") {
      return "SALONES EN ESPAÑA";
    }
    if (cid === "AT") {
      return "Kontakt in Österreich";
    }
    return `STUDIOS IN ${countryName}`;
  };

  const contactTitle = (cid: string) => {
    if (cid === "ES") {
      return "CONTÁCTANOS POR MAIL O TELÉFONO";
    }
    if (cid === "DE") {
      return "CONTACT US";
    }
    return "CONTACT US BY MAIL OR PHONE";
  };

  return responseData.length ? (
    <div>
      {responseData.length > 0 ? (
        <div className="contactAdresses">
          {responseData[0].InfoSalons.length > 0 ? (
            <>
              <h2>{salonTitle(internalCountryId, internalCountryName)}</h2>

              <ul className="tabContainer wrap">
                {responseData[0].InfoSalons.map((salon) => (
                  <li key={salon.Id}>
                    <strong dangerouslySetInnerHTML={{ __html: salon.Name }} />
                    <p dangerouslySetInnerHTML={{ __html: salon.Address }} />
                    <p dangerouslySetInnerHTML={{ __html: salon.Phone }} />
                  </li>
                ))}
              </ul>
            </>
          ) : null}

          {responseData[0].InfoContacts.length > 0 ? (
            <>
              <h2>{contactTitle(internalCountryId)}</h2>

              <ul className="tabContainer wrap">
                {responseData[0].InfoContacts.map((contact) => (
                  <li key={contact.Title}>
                    <strong
                      dangerouslySetInnerHTML={{ __html: contact.Title }}
                    />
                    <p dangerouslySetInnerHTML={{ __html: contact.Content }} />
                  </li>
                ))}
              </ul>
            </>
          ) : null}
        </div>
      ) : null}

      {formCode.toLowerCase().indexOf("contact") > -1 &&
      responseData.length === 0 ? (
        <div className="contactAdresses">
          {/* <img src="https://www.systemprofessional.com/i/pages/contact-us.jpg" alt="contact us" /> */}
        </div>
      ) : null}
    </div>
  ) : null;
};

export default SalonBox;
