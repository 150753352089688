// types
import getLanguage from "core/infrastructure/getLanguage";
import { EFieldName } from "configurations/EFieldType";

import IField from "../../types/IField";
import IPopup from "../../types/IPopup";
import IButton from "../../types/IButton";
import ISubmit from "../../types/ISubmit";
import ITranslation from "../../types/ITranslation";

// configuration
import getSelectBoxConfiguration from "../infrastructure/getSelectBoxConfiguration";
import getFieldConfiguration from "../infrastructure/getFieldConfiguration";
import { EInputType } from "../../configurations/EInputType";

function getFields(
  fields: Array<IField>,
  translations: Array<ITranslation>,
  countries: Array<any>,
  brands: Array<any>,
  months: Array<any>,
  days: Array<any>,
  years: Array<any>,
  provinces: Array<any>
): Array<IField> {
  const privacyField = fields.find((field) => field.Name === "Privacy");

  if (
    privacyField &&
    (getLanguage() === "de-DE" || getLanguage() === "de-AT")
  ) {
    const newFields = [
      EFieldName.DEPrivacyPolicy,
      EFieldName.DETermsAndConditions,
    ].map((name) => {
      const field = JSON.parse(JSON.stringify(privacyField));
      field.Name = name;
      return field;
    });
    const idx = fields.indexOf(privacyField);

    if (idx > 0) {
      fields = [
        ...fields.slice(0, idx),
        ...newFields,
        ...fields.slice(idx + 1),
      ];
    }
  }

  return fields.map((configField) =>
    mapFieldConfigToField(
      configField,
      translations,
      countries,
      brands,
      months,
      days,
      years,
      provinces
    )
  );
}

function mapFieldConfigToField(
  field: IField,
  translations: Array<ITranslation>,
  countries: Array<any>,
  brands: Array<any>,
  months: Array<any>,
  days: Array<any>,
  years: Array<any>,
  provinces: Array<any>
): IField {
  // console.log(brands, "mapFieldConfigToField");
  const translation = getTranslation(field.Name.toString(), translations);
  const config = getFieldConfiguration(field.Name);

  field.ErrorMessages = {
    InvalidErrorMessage: translation.ErrorMessages.InvalidErrorMessage,
    MissingErrorMessage: translation.ErrorMessages.MissingErrorMessage,
  };
  field.Id = translation.Id;
  field.Placeholder = translation.Placeholder;
  field.Label = translation.Label;
  field.IsAggrement = translation.IsAggrement;
  field.InputType = config.InputType;
  field.Options = getSelectBoxConfiguration(
    field,
    translations,
    countries,
    brands,
    months,
    days,
    years,
    provinces
  );
  field.Validation = {
    Missing: false,
    Invalid: false,
  };
  // console.log(field, "mapFieldConfigToField");

  return field;
}

function getPopups(
  popups: Array<IPopup>,
  translations: Array<ITranslation>
): Array<IPopup> {
  return popups.map((popup) => getPopup(popup, translations));
}

function getPopup(popup: IPopup, translations: Array<ITranslation>): IPopup {
  const translation = getTranslation(popup.Name.toString(), translations);
  popup.Label = translation.Label;
  return popup;
}

function getButton(
  buttonConfig: IButton,
  translations: Array<ITranslation>
): IButton {
  const translation = getTranslation(
    buttonConfig.Name.toString(),
    translations
  );
  buttonConfig.Label = translation.Label;
  return buttonConfig;
}

function aggrementsToTranslations(aggrements: Array<any>): Array<ITranslation> {
  const result: Array<ITranslation> = [];
  // console.log("aggrementsToTranslations", aggrements);
  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const key in aggrements) {
    result.push({
      Id: aggrements[key].Id,
      Name: aggrements[key].Name,
      Label: aggrements[key].Label,
      IsAggrement: true,
      Required: aggrements[key].Required,
      ErrorMessages: {
        InvalidErrorMessage: aggrements[key].ErrorMessage,
        MissingErrorMessage: aggrements[key].ErrorMessage,
      },
    } as ITranslation);
  }
  // console.log(result);

  return result;
}

// temporary
function mapTranslations(
  translations: Array<ITranslation>
): Array<ITranslation> {
  const result: Array<ITranslation> = [];
  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const key in translations) {
    result.push(translations[key]);
  }

  return result;
}

function getTranslation(
  forWhat: string,
  translations: Array<ITranslation>
): any {
  // console.log(forWhat, "forWhat");
  const translationsForField = translations.filter(
    (x) => x.Name.toLocaleLowerCase() === forWhat.toLocaleLowerCase()
  );
  // console.log(translationsForField, translationsForField.length, "translation");
  if (translationsForField.length === 0)
    throw new Error(`Missing translations for Type ${forWhat.toString()}`);

  return translationsForField[0];
}

function prepareSubmitJson(
  fields: Array<IField>,
  formCode: string,
  sendAdditionalFields: boolean,
  formLang: string
): ISubmit {
  const result: ISubmit = {
    FormCode: formCode,
    Agreements: [],
    AdditionalField: {},
    FormLang: formLang,
  };

  // console.log("fields", fields, sendAdditionalFields);

  // console.log("sendAdditionalFields", sendAdditionalFields);

  // standard
  const standard = fields.filter(
    (x) =>
      !x.IsAggrement && !x.IsAdditional && x.InputType !== EInputType.captcha
  );
  standard.forEach((x: IField) => {
    result[x.NameSend] = x.Value;
  });

  const deAgrrementsNames = [
    EFieldName.DEPrivacyPolicy,
    EFieldName.DETermsAndConditions,
  ];
  const aggrements = fields.filter(
    (x) => x.IsAggrement && !deAgrrementsNames.includes(x.Name)
  );
  aggrements.forEach((x) => result.Agreements.push(preparAggrement(x)));

  // For de-DE and de-AT we will merge privacy agreements
  if (formLang === "de-DE" || formLang === "de-AT") {
    const deAgreements = fields.filter(
      (x) => x.IsAggrement && deAgrrementsNames.includes(x.Name)
    );

    if (deAgreements.length > 0) {
      const value = deAgreements.reduce(
        (acc, cur) => cur.Value === "true" && acc,
        true
      );
      result.Agreements.push({
        Id: deAgreements[0].Id, // All de-DE and de-AT privacy agreements have same Id
        Value: value,
      });
    }
  }

  // additional
  if (sendAdditionalFields) {
    const additional = fields.filter((x) => x.IsAdditional);
    additional.forEach((x: IField) => {
      result.AdditionalField[x.Name] = x.Value;
    });
  }

  // captcha
  const captcha = fields.filter((x) => x.InputType === EInputType.captcha)[0];
  if (captcha) {
    result.Response = captcha.Value;
    result.Site = captcha.SiteKey;
  }

  return result;
}

function preparAggrement(aggrement: IField): any {
  return {
    Id: aggrement.Id,
    Value: aggrement.Value === "true",
  };
}

export {
  prepareSubmitJson,
  aggrementsToTranslations,
  mapTranslations,
  getButton,
  getFields,
  getPopups,
};
