import { FC } from "react";

// types
import IButton from "../../types/IButton";

export interface Props {
  button: IButton;
  sending: boolean;
  onClick: (evt: any) => void;
}

const SubmitButton: FC<Props> = function ({ button, sending, onClick }) {
  return (
    <div className={button.Classes.Container}>
      <button
        disabled={sending}
        className={button.Classes.Button}
        onClick={onClick}
        type="submit"
      >
        {button.Label}
      </button>
    </div>
  );
};

export default SubmitButton;
