// types
import IField from "../../types/IField";
import IOption from "../../types/IOption";
import ITranslation from "../../types/ITranslation";
import { EFieldName } from "../../configurations/EFieldType";

export default function getSelectBoxConfiguration(
  field: IField,
  translations: Array<ITranslation>,
  countries: Array<any>,
  brands: Array<any>,
  months: Array<any>,
  days: Array<any>,
  years: Array<any>,
  provinces: Array<any>
): Array<IOption> {
  if (field.Name === EFieldName.CountrySelector) {
    return countries.map((country) => getCountryOption(country));
  }
  if (field.Name === EFieldName.BrandsSelector) {
    return brands.map((brand) => getBrandsOption(brand));
  }
  if (field.Name === EFieldName.ProvinceSelector) {
    return provinces.map((province) => getProvinceOption(province));
  }
  if (field.Name === EFieldName.Month) {
    return months.map((month, index) => getMonthOptionByNumber(month, index));
  }
  if (field.Name === EFieldName.Day) {
    return days.map((d) => getMonthOption(d));
  }
  if (field.Name === EFieldName.Year) {
    return years.map((d) => getMonthOption(d));
  }
  if (!field.Options) {
    return [];
  }

  return field.Options.map((x) => getOption(x.Name, translations));
}

function getMonthOptionByNumber(month: any, index: number): IOption {
  return mapToOptionMonth(month, index);
}

function mapToOptionMonth(month: any, index: any): IOption {
  const i = index + 1;
  return {
    Name: i.toString(),
    Label: month,
  };
}

function getOption(option: string, translations: Array<ITranslation>): IOption {
  const translation = translations.filter((x) => x.Name.valueOf() === option);
  throwErrorIfNotExists(option, translation);
  return mapToOption(translation[0].Name, translation[0].Label);
}

function getProvinceOption(province: any): IOption {
  const code = province.Province ? province.Province : "";
  const name = province.Province ? province.Province : "";
  return mapToOption(code, name);
}

function getCountryOption(country: any): IOption {
  const name = country.Country ? country.Country : country.Name;
  const code = country.Code ? country.Code : country.Id;
  return mapToOption(code, name);
}

function getBrandsOption(brand: any): IOption {
  const name = brand.Name ? brand.Name : "";
  const code = brand.Code ? brand.Code : "";
  return mapToOption(code, name);
}

function getMonthOption(month: any): IOption {
  return mapToOption(month, month);
}

function mapToOption(name: string, label: string): IOption {
  return {
    Name: name,
    Label: label,
  };
}

function throwErrorIfNotExists(name: string, occurence: Array<any>) {
  if (occurence.length !== 1) {
    throw new Error(`Can't find or to many configuration for type: ${name}`);
  }
}
