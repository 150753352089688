import { ChangeEvent, FC } from "react";

import IOption from "../../types/IOption";

export interface Props {
  options: IOption[];
  cssClassName: string;
  name: string;
  value: string;
  onChange: (evt: ChangeEvent) => void;
}

const SelectBox: FC<Props> = function ({
  cssClassName,
  name,
  value,
  onChange,
  options,
}) {
  const emptyLabel = "---";
  const getLabelByName = (items: IOption[] | [], label: string): string => {
    const occurence = items.find((x) => x.Name.toString() === label);
    return occurence?.Label || emptyLabel;
  };

  return (
    <div className="ctrl-select__styled">
      <div className="ctrl-select__text">{getLabelByName(options, value)}</div>
      <div className="ctrl-select__button" />
      <select
        id={name}
        data-testid="selectBox"
        onChange={onChange}
        value={value}
        className={cssClassName}
        name={name}
      >
        <option style={{ display: "none" }}>{emptyLabel}</option>
        {options?.map(({ Name, Label }) => (
          <option key={`SelectBoxOption-${Name}`} value={Name}>
            {Label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectBox;
