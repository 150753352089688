// types
import IFieldConfig from "../types/IFieldConfig";

// configuration
import { EFieldName } from "./EFieldType";
import { EInputType } from "./EInputType";
import { EValidatorType } from "./EValidatorType";

const configuration = [
  {
    Type: EFieldName.Country,
    InputType: EInputType.select,
    ValidatorName: EValidatorType.selectValidator,
  },
  {
    Type: EFieldName.ImType,
    InputType: EInputType.select,
    ValidatorName: EValidatorType.selectValidator,
  },
  {
    Type: EFieldName.ZContactTypeHair,
    InputType: EInputType.select,
    ValidatorName: EValidatorType.selectValidator,
  },
  {
    Type: EFieldName.ZContactTypeNail,
    InputType: EInputType.select,
    ValidatorName: EValidatorType.selectValidator,
  },
  {
    Type: EFieldName.EmailAddress,
    InputType: EInputType.text,
    ValidatorName: EValidatorType.emailValidator,
  },
  {
    Type: EFieldName.ConfirmEmailAddress,
    InputType: EInputType.text,
    ValidatorName: EValidatorType.emailValidator,
  },
  {
    Type: EFieldName.FirstName,
    InputType: EInputType.text,
    ValidatorName: EValidatorType.nameValidator,
  },
  {
    Type: EFieldName.LastName,
    InputType: EInputType.text,
    ValidatorName: EValidatorType.nameValidator,
  },
  {
    Type: EFieldName.SalonNameReq,
    InputType: EInputType.text,
    ValidatorName: EValidatorType.nameValidator,
  },
  {
    Type: EFieldName.SalonAddressReq,
    InputType: EInputType.text,
    ValidatorName: EValidatorType.nameValidator,
  },
  {
    Type: EFieldName.SalonPhoneReq,
    InputType: EInputType.text,
    ValidatorName: EValidatorType.phoneValidator,
  },
  {
    Type: EFieldName.SalonManagerEmailReq,
    InputType: EInputType.text,
    ValidatorName: EValidatorType.emailValidator,
  },
  {
    Type: EFieldName.StylistNumbPerSalonReq,
    InputType: EInputType.text,
    ValidatorName: EValidatorType.nameValidator,
  },
  {
    Type: EFieldName.AdditionalInformation,
    InputType: EInputType.textarea,
    ValidatorName: EValidatorType.textareaValidator,
  },
  {
    Type: EFieldName.Phone,
    InputType: EInputType.text,
    ValidatorName: EValidatorType.phoneValidator,
  },
  {
    Type: EFieldName.Subject,
    InputType: EInputType.select,
    ValidatorName: EValidatorType.selectValidator,
  },
  {
    Type: EFieldName.SubjectDJ,
    InputType: EInputType.text,
    ValidatorName: EValidatorType.nameValidator,
  },
  {
    Type: EFieldName.PassionistasSubject,
    InputType: EInputType.text,
    ValidatorName: EValidatorType.nameValidator,
  },
  {
    Type: EFieldName.PolicyAndTCAgrement,
    InputType: EInputType.checkbox,
    ValidatorName: EValidatorType.checkboxValidator,
  },
  {
    Type: EFieldName.Im,
    InputType: EInputType.select,
    ValidatorName: EValidatorType.selectValidator,
  },
  {
    Type: EFieldName.DoYouUseWellaInYourSalon,
    InputType: EInputType.select,
    ValidatorName: EValidatorType.selectValidator,
  },
  {
    Type: EFieldName.HowDidYouHearAboutWellaPassionistas,
    InputType: EInputType.select,
    ValidatorName: EValidatorType.selectValidator,
  },
  {
    Type: EFieldName.Message,
    InputType: EInputType.textarea,
    ValidatorName: EValidatorType.textareaValidator,
  },
  {
    Type: EFieldName.Newsletter,
    InputType: EInputType.checkbox,
    ValidatorName: EValidatorType.checkboxValidator,
  },
  {
    Type: EFieldName.Privacy,
    InputType: EInputType.checkbox,
    ValidatorName: EValidatorType.checkboxValidator,
  },
  {
    Type: EFieldName.DEPrivacyPolicy,
    InputType: EInputType.checkbox,
    ValidatorName: EValidatorType.checkboxValidator,
  },
  {
    Type: EFieldName.DETermsAndConditions,
    InputType: EInputType.checkbox,
    ValidatorName: EValidatorType.checkboxValidator,
  },
  {
    Type: EFieldName.TextAboveAgr,
    InputType: EInputType.label,
    ValidatorName: EValidatorType.none,
  },
  {
    Type: EFieldName.TextAboveAdditionalInformation,
    InputType: EInputType.label,
    ValidatorName: EValidatorType.none,
  },
  {
    Type: EFieldName.UnsubBottomMsg,
    InputType: EInputType.label,
    ValidatorName: EValidatorType.none,
  },
  {
    Type: EFieldName.CountrySelector,
    InputType: EInputType.select,
    ValidatorName: EValidatorType.none,
  },
  {
    Type: EFieldName.ProvinceSelector,
    InputType: EInputType.select,
    ValidatorName: EValidatorType.none,
  },
  {
    Type: EFieldName.Month,
    InputType: EInputType.select,
    ValidatorName: EValidatorType.selectValidator,
  },
  {
    Type: EFieldName.Day,
    InputType: EInputType.select,
    ValidatorName: EValidatorType.selectValidator,
  },
  {
    Type: EFieldName.Year,
    InputType: EInputType.select,
    ValidatorName: EValidatorType.selectValidator,
  },
  {
    Type: EFieldName.Captcha,
    InputType: EInputType.captcha,
    ValidatorName: EValidatorType.captchaValidator,
  },
  {
    Type: EFieldName.ProductInformation,
    InputType: EInputType.text,
    ValidatorName: EValidatorType.nameValidator,
  },
  {
    Type: EFieldName.Otherhandles,
    InputType: EInputType.text,
    ValidatorName: EValidatorType.nameValidator,
  },
  {
    Type: EFieldName.Twitter,
    InputType: EInputType.text,
    ValidatorName: EValidatorType.nameValidator,
  },
  {
    Type: EFieldName.Pinterest,
    InputType: EInputType.text,
    ValidatorName: EValidatorType.nameValidator,
  },
  {
    Type: EFieldName.Facebook,
    InputType: EInputType.text,
    ValidatorName: EValidatorType.nameValidator,
  },
  {
    Type: EFieldName.Instagramhandle,
    InputType: EInputType.text,
    ValidatorName: EValidatorType.nameValidator,
  },
  {
    Type: EFieldName.TikTokhandle,
    InputType: EInputType.text,
    ValidatorName: EValidatorType.nameValidator,
  },
  {
    Type: EFieldName.PersonalFacebook,
    InputType: EInputType.text,
    ValidatorName: EValidatorType.nameValidator,
  },
  {
    Type: EFieldName.ProfessionalFacebook,
    InputType: EInputType.text,
    ValidatorName: EValidatorType.nameValidator,
  },
  {
    Type: EFieldName.Youtube,
    InputType: EInputType.text,
    ValidatorName: EValidatorType.nameValidator,
  },
  {
    Type: EFieldName.PassQuestionNo1,
    InputType: EInputType.text,
    ValidatorName: EValidatorType.nameValidator,
  },
  {
    Type: EFieldName.PassQuestionNo2,
    InputType: EInputType.text,
    ValidatorName: EValidatorType.nameValidator,
  },
  {
    Type: EFieldName.PassionistasQ3,
    InputType: EInputType.radio,
    ValidatorName: EValidatorType.nameValidator,
  },
  {
    Type: EFieldName.PassionistasQ4,
    InputType: EInputType.radio,
    ValidatorName: EValidatorType.nameValidator,
  },
  {
    Type: EFieldName.SebastianInsidersNo1,
    InputType: EInputType.text,
    ValidatorName: EValidatorType.nameValidator,
  },
  {
    Type: EFieldName.SebastianInsidersNo2,
    InputType: EInputType.text,
    ValidatorName: EValidatorType.nameValidator,
  },
  {
    Type: EFieldName.SebastianInsidersQ3,
    InputType: EInputType.radio,
    ValidatorName: EValidatorType.nameValidator,
  },
  {
    Type: EFieldName.SebastianInsidersQ4,
    InputType: EInputType.radio,
    ValidatorName: EValidatorType.nameValidator,
  },
  {
    Type: EFieldName.Birthday,
    InputType: EInputType.text,
    ValidatorName: EValidatorType.nameValidator,
  },
  {
    Type: EFieldName.Adress,
    InputType: EInputType.text,
    ValidatorName: EValidatorType.nameValidator,
  },
  {
    Type: EFieldName.City,
    InputType: EInputType.text,
    ValidatorName: EValidatorType.nameValidator,
  },
  {
    Type: EFieldName.ZipCode,
    InputType: EInputType.text,
    ValidatorName: EValidatorType.nameValidator,
  },
  {
    Type: EFieldName.PostalCode,
    InputType: EInputType.text,
    ValidatorName: EValidatorType.nameValidator,
  },
  {
    Type: EFieldName.Adress2,
    InputType: EInputType.text,
    ValidatorName: EValidatorType.nameValidator,
  },
  {
    Type: EFieldName.AdressSPGain,
    InputType: EInputType.text,
    ValidatorName: EValidatorType.nameValidator,
  },
  {
    Type: EFieldName.NumberofStylists,
    InputType: EInputType.text,
    ValidatorName: EValidatorType.numberofStylistsValidator,
  },
  {
    Type: EFieldName.CareBrand,
    InputType: EInputType.text,
    ValidatorName: EValidatorType.careBrandValidator,
  },
  {
    Type: EFieldName.WhatPrice,
    InputType: EInputType.text,
    ValidatorName: EValidatorType.priceValidator,
  },
  {
    Type: EFieldName.CountrySelectorSP,
    InputType: EInputType.select,
    ValidatorName: EValidatorType.selectValidator,
  },
  {
    Type: EFieldName.ClientNumber,
    InputType: EInputType.text,
    ValidatorName: EValidatorType.numberValidator,
  },
  {
    Type: EFieldName.ProductName,
    InputType: EInputType.text,
    ValidatorName: EValidatorType.nameValidator,
  },
  {
    Type: EFieldName.BrandsSelector,
    InputType: EInputType.select,
    ValidatorName: EValidatorType.selectValidator,
  },
  {
    Type: EFieldName.BatchCode,
    InputType: EInputType.text,
    ValidatorName: EValidatorType.nameValidator,
  },
  {
    Type: EFieldName.ProductEanCode,
    InputType: EInputType.text,
    ValidatorName: EValidatorType.nameValidator,
  },
  {
    Type: EFieldName.Description,
    InputType: EInputType.text,
    ValidatorName: EValidatorType.nameValidator,
  },
  {
    Type: EFieldName.FreelanceHairdresser,
    InputType: EInputType.text,
    ValidatorName: EValidatorType.nameValidator,
  },
  {
    Type: EFieldName.HairNailSalonOwner,
    InputType: EInputType.text,
    ValidatorName: EValidatorType.nameValidator,
  },
  {
    Type: EFieldName.InSalonHairdresser,
    InputType: EInputType.text,
    ValidatorName: EValidatorType.nameValidator,
  },
  {
    Type: EFieldName.HairOnlySalonOwner,
    InputType: EInputType.text,
    ValidatorName: EValidatorType.nameValidator,
  },
  {
    Type: EFieldName.ProfessionalType,
    InputType: EInputType.select,
    ValidatorName: EValidatorType.selectValidator,
  },
] as Array<IFieldConfig>;

export { configuration };
