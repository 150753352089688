import React, { FC } from "react";
import Recaptcha from "react-google-recaptcha";
import { getLanguageToCaptcha } from "../../core/infrastructure/getLanguageToCaptcha";

interface Props {
  setPassedCaptcha: () => string;
  setFailedCaptcha: () => void;
  sitekey: string;
}
declare global {
  interface Window {
    recaptchaOptions: any;
  }
}
window.recaptchaOptions = {
  lang: getLanguageToCaptcha() === "us" ? "en" : getLanguageToCaptcha(),
};

const Captcha: FC<Props> = function ({
  setPassedCaptcha,
  setFailedCaptcha,
  sitekey,
}) {
  return (
    <Recaptcha
      data-testid="captcha"
      sitekey={sitekey}
      onChange={setPassedCaptcha}
      onErrored={setFailedCaptcha}
      onExpired={setFailedCaptcha}
    />
  );
};

export default Captcha;
