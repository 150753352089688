import { ChangeEvent, FC } from "react";

// types
import IField from "../../types/IField";

export interface Props {
  field: IField;
  onChange: (evt: ChangeEvent<HTMLInputElement>) => void;
}

const TextBox: FC<Props> = function ({ field, onChange }) {
  return (
    <input
      id={field.Name}
      name={field.Name}
      type="text"
      className={field.Classes.Input}
      onChange={onChange}
      placeholder={field.Placeholder}
      value={field.Value || ""}
    />
  );
};

export default TextBox;
