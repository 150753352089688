enum EValidatorType {
  checkboxValidator = "checkboxValidator",
  emailValidator = "emailValidator",
  nameValidator = "nameValidator",
  phoneValidator = "phoneValidator",
  selectValidator = "selectValidator",
  textareaValidator = "textareaValidator",
  captchaValidator = "captchaValidator",
  numberValidator = "numberValidator",
  careBrandValidator = "careBrandValidator",
  priceValidator = "priceValidator",
  numberofStylistsValidator = "numberofStylistsValidator",
  none = "none",
}

export { EValidatorType };
