enum EInputType {
  text = "text",
  checkbox = "checkbox",
  textarea = "textarea",
  select = "select",
  label = "label",
  captcha = "captcha",
  radio = "radio",
}

export { EInputType };
