// types
import IField from "../../types/IField";
import IAggrementsData from "../../types/IAggrementsData";

// core
import regexFactory from "../infrastructure/regexFactory";

// config
import { EFieldName } from "../../configurations/EFieldType";
import { EInputType } from "../../configurations/EInputType";
import { configuration } from "../../configurations/FieldToComponentMapperConfiguration";

function validate(
  field: IField,
  fields: Array<IField>,
  aggrementsData: Array<IAggrementsData>
): IField {
  field.Validation.Invalid = false;
  field.Validation.Missing = false;

  if (!field.Value && !isCheckbox(field.Name) && !isLabel(field.Name)) {
    if (field.Name === "ProvinceSelector") {
      const CountryCode =
        fields.filter((x) => x.Name === "CountrySelector")[0].Value ===
        undefined
          ? ""
          : fields.filter((x) => x.Name === "CountrySelector")[0].Value;
      if (CountryCode !== "CA") {
        field.Validation.Missing = false;
      } else if (CountryCode === "CA" && field.Value === "") {
        field.Validation.Missing = true;
      }
    } else {
      field.Validation.Missing = true;
    }
  } else if (!regexFactory().create(field.Name, field.Value)) {
    field.Validation.Invalid = true;
  }

  if (
    !field.Validation.Missing &&
    field.Name === EFieldName.ConfirmEmailAddress
  ) {
    field.Validation.Invalid = specialValidationForEmail(field, fields);
  }

  if (field.Name === "Day") {
    field.Validation.Missing = false;
    field.Validation.Invalid = false;
  }

  if (field.Name === "Month") {
    field.Validation.Missing = false;
    field.Validation.Invalid = false;
  }
  if (field.Name === "Year") {
    field.Validation.Missing = false;
    field.Validation.Invalid = false;
  }
  if (field.Required === false) {
    field.Validation.Missing = false;
  }

  if (isCheckbox(field.Name) && field.IsAggrement) {
    aggrementsData.forEach((data) => {
      if (data.Id === field.Id) {
        if (
          (field.Value === "false" || typeof field.Value === "undefined") &&
          data.Required === false
        ) {
          field.Validation.Missing = false;
          field.Validation.Invalid = false;
        }
      }
    });
  }

  // if(field.Name === "Phone")  {
  //     field.Validation.Missing = false;
  // }

  // if(field.Name === "Newsletter") {
  //     if(field.Label.indexOf("*") === -1) {
  //         field.Validation.Missing = false;
  //         field.Validation.Invalid = false;
  //     }
  // }

  return field;
}

function isCheckbox(type: EFieldName) {
  return (
    configuration.filter((x) => x.Type === type)[0].InputType ===
    EInputType.checkbox
  );
}

function isLabel(type: EFieldName) {
  return (
    configuration.filter((x) => x.Type === type)[0].InputType ===
    EInputType.label
  );
}

function specialValidationForEmail(
  field: IField,
  fields: Array<IField>
): boolean {
  return (
    fields
      .filter((x) => x.Name === EFieldName.EmailAddress)[0]
      .Value.toLowerCase() !== field.Value.toLowerCase()
  );
}

export { validate };
