import { FC } from "react";

interface Props {
  cssClassName: string;
  text: string;
}

const Paragraph: FC<Props> = function ({ cssClassName, text }) {
  return (
    <div className={cssClassName} dangerouslySetInnerHTML={{ __html: text }} />
  );
};

export default Paragraph;
