import { FC, FormEvent } from "react";

import IField from "types/IField";

interface Props {
  field: IField;
  onChange: (event: FormEvent<HTMLTextAreaElement>) => void;
}

const TextArea: FC<Props> = function ({ field, onChange }) {
  return (
    <textarea
      id={field.Name}
      name={field.Name}
      className={field.Classes.Input}
      placeholder={field.Placeholder}
      value={field.Value}
      onChange={onChange}
    />
  );
};

export default TextArea;
