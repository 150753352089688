import { FC } from "react";

interface Props {
  cssClassName: string;
  htmlFor: string;
  text: string;
}

const Label: FC<Props> = function ({ cssClassName, htmlFor, text }) {
  return (
    <label
      className={cssClassName}
      htmlFor={htmlFor}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
};

export default Label;
