// configurations
import { EFieldName } from "../../configurations/EFieldType";
import { EValidatorType } from "../../configurations/EValidatorType";
import { configuration } from "../../configurations/FieldToComponentMapperConfiguration";

// validators
import emailValidator from "./validators/emailValidator";
import checkboxValidator from "./validators/checkboxValidator";
import selectValidator from "./validators/selectValidator";
import textareaValidator from "./validators/textareaValidator";
import nameValidator from "./validators/nameValidator";
import numberValidator from "./validators/numberValidator";
import phoneValidator from "./validators/phoneValidator";
import captchaValidator from "./validators/captchaValidator";
import priceValidator from "./validators/priceValidator";

export default function regexFactory() {
  return {
    create(type: EFieldName, value: string): boolean {
      const config = configuration.filter((x) => x.Type === type)[0];
      // console.log("regex", config, value, type, config.ValidatorName );
      switch (config.ValidatorName) {
        case EValidatorType.emailValidator:
          return emailValidator(value);

        case EValidatorType.numberValidator:
          return numberValidator(value);

        case EValidatorType.phoneValidator:
          return numberValidator(value, 19);

        case EValidatorType.nameValidator:
          return nameValidator(value);

        case EValidatorType.careBrandValidator:
          return nameValidator(value, 1, 50);

        case EValidatorType.priceValidator:
          return priceValidator(value, 1000);

        case EValidatorType.numberofStylistsValidator:
          return priceValidator(value, 100);

        case EValidatorType.selectValidator:
          return selectValidator(value);

        case EValidatorType.checkboxValidator:
          return checkboxValidator(value);

        case EValidatorType.textareaValidator:
          return textareaValidator(value);

        case EValidatorType.captchaValidator:
          return captchaValidator(value);

        case EValidatorType.none:
          return true;

        default:
          throw new Error(`Validator for ${type} not exists.`);
      }
    },
  };
}
