import * as React from "react";
import { render } from "react-dom";

// types
import IField from "./types/IField";
import IFormConfig from "./types/IFormConfig";
import ITranslation from "./types/ITranslation";
import IAggrementsData from "./types/IAggrementsData";

// core
import getAgreements from "./services/getAgreements";
import getTranslations from "./services/getTranslations";
import getLanguage from "./core/infrastructure/getLanguage";
import parseFormConfig from "./core/infrastructure/parseFormConfig";

// components
import Form from "./components/Form";
import getCountries from "./services/getCountries";
import getBrands from "./services/getBrands";
import getMonth from "./services/getMonth";
import getDay from "./services/getDay";
import getYears from "./services/getYears";
import getProvinces from "./services/getProvinces";

// mappers
import {
  mapTranslations,
  aggrementsToTranslations,
} from "./core/logic/Mappers";

import "./styles/index.scss";

interface iState {
  FormConfig: IFormConfig;
  Translations: Array<ITranslation>;
  Language: string;
  Countries: Array<string>;
  Brands: Array<string>;
  Months: Array<string>;
  Days: Array<string>;
  Years: Array<string>;
  TranslationsReady: boolean;
  CountriesReady: boolean;
  BrandsReady: boolean;
  FormType: string;
  FormUrl: string;
  ProvincesReady: boolean;
  Provinces: Array<string>;
  AggrementsData: Array<IAggrementsData>;
  Maintenance: boolean;
}

class App extends React.Component<any, iState> {
  constructor(props: any) {
    super(props);
    this.state = {
      ...this.state,
      Maintenance: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.loadTranslationsAndAggrements(
      this.loadFormConfig(),
      this.setLanguage(),
      this.loadFormType()
    );
    this.loadCountries();
    this.loadProvinces();
    this.loadDay();
    this.loadYears();
    this.loadBrands();
  }

  loadFormType = (): string => {
    const formConfig = this.checkFormType();
    return formConfig;
  };

  checkFormType = (): string => {
    let formType = "prod";
    const url: string = window.location.origin;
    if (
      url.indexOf("localhost") > -1 ||
      url.indexOf("dev") > -1 ||
      url.indexOf("local") > -1
    ) {
      formType = "test";
      return formType;
    }
    if (url.indexOf("staging") > -1 || url.indexOf("aws") > -1) {
      formType = "stage";
      return formType;
    }
    return formType;
  };

  loadFormConfig = (): string => {
    const formConfig = parseFormConfig();
    const language = getLanguage();
    const checkType = this.checkFormType();
    this.setState({
      FormConfig: formConfig,
      Language: language,
      FormType: checkType,
      FormUrl: formConfig.FormUrl,
    });

    return formConfig.FormCode;
  };

  setLanguage = (): string => {
    const language = getLanguage();
    this.setState({
      Language: language,
    });

    return language;
  };

  loadTranslationsAndAggrements = (
    formCode: string,
    language: string,
    formType: string
  ) => {
    // console.log("111", formCode, language, formType);
    getTranslations(language, formType)
      .then((x) =>
        this.setState({
          Translations: mapTranslations(x.Data.Translations), // temporary => translationsToFields
        })
      )
      .then((x) =>
        getAgreements(formCode, language, formType)
          .then((aggrements) =>
            this.setState({
              Translations: this.state.Translations.concat(
                aggrementsToTranslations(aggrements.Data.Agreements)
              ),
              AggrementsData: aggrements.Data.Agreements,
            })
          )
          .then((y) =>
            this.setState({
              Months: getMonth(this.state.Translations),
            })
          )
          .then((y) =>
            this.setState({
              TranslationsReady: true,
            })
          )
      );
  };

  loadDay = () => {
    this.setState({
      Days: getDay(),
    });
  };

  loadYears = () => {
    this.setState({
      Years: getYears(),
    });
  };

  loadCountries = () => {
    const lang = getLanguage();
    getCountries(lang)
      .then((x) =>
        this.setState({
          Countries: x.Data.Countries,
        })
      )
      .then((x) =>
        this.setState({
          CountriesReady: true,
        })
      );
  };

  loadBrands = () => {
    getBrands()
      .then((x) =>
        this.setState({
          Brands: x.Data,
        })
      )
      .then((x) =>
        this.setState({
          BrandsReady: true,
        })
      );
  };

  loadProvinces = () => {
    getProvinces()
      .then((x) =>
        this.setState({
          Provinces: x.Data,
        })
      )
      .then((x) =>
        this.setState({
          ProvincesReady: true,
        })
      );
  };

  render(): JSX.Element {
    const {
      FormConfig,
      Translations,
      TranslationsReady,
      CountriesReady,
      Countries,
      Months,
      Days,
      Years,
      FormType,
      FormUrl,
      Provinces,
      AggrementsData,
      Brands,
      Maintenance,
    } = this.state;

    if (Maintenance) {
      return (
        <div className="page">
          <h1 className="title">
            A little site maintenance goes a long way.
            <br /> We&apos;ll be up and running again soon.
          </h1>
        </div>
      );
    }

    if (TranslationsReady && CountriesReady) {
      return (
        <Form
          key={`Form-${FormConfig.FormCode}`}
          FormConfig={FormConfig}
          Translations={Translations}
          Countries={Countries}
          Brands={Brands}
          Months={Months}
          Days={Days}
          Years={Years}
          FormType={FormType}
          FormUrl={FormUrl}
          Provinces={Provinces}
          AggrementsData={AggrementsData}
        />
      );
    }
    return <div>Loading data...</div>;
  }
}

if (getLanguage() !== "ru-RU") {
  render(<App />, document.getElementById("app"));
}
