import * as React from "react";

// components

// types
import IPopup from "../types/IPopup";
import IApiResponse from "../types/IApiResponse";

interface iProps {
  Popups: Array<IPopup>;
  Response: IApiResponse;
  FormLang?: string;
  FormCode: string;
  PopupUrl?: string;
}

interface iState {
  isVisible: boolean;
}

/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
class Popup extends React.Component<iProps, iState> {
  constructor(props: iProps) {
    super(props);
    this.state = {
      ...this.state,
      isVisible: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (this.props.Response !== nextProps.Response) {
      this.setState({
        isVisible: true,
      } as iState);
    }
  }

  close = (): void => {
    const redirect = window.location.origin;
    this.setState(
      {
        isVisible: false,
      } as iState,
      () => {
        if (
          this.props.Response.ErrorMessage === "" ||
          this.props.Response.ErrorMessage === null
        ) {
          this.checkSite();
        }
      }
    );
  };

  getEducationBook = (e: any): void => {
    e.preventDefault();
    const url = this.props.PopupUrl
      ? `${window.location.origin}${this.props.PopupUrl}`
      : "";
    window.open(url, "_blank");
  };

  checkSite() {
    const redirect = window.location.hostname;
    // const url = window.location.href;
    if (
      redirect === "localhost" ||
      window.location.href.indexOf("clairolpro") > -1
    ) {
      window.location.replace(window.location.origin);
    } else {
      const url =
        window.location.origin.indexOf("wella") > -1
          ? `${window.location.origin}/professional/${this.props.FormLang}`
          : `${window.location.origin}/${this.props.FormLang}`;
      // console.log("url", url);
      window.location.replace(url);
    }
  }

  selectPopup(): IPopup {
    if (
      this.props.Response.ErrorMessage === "" ||
      this.props.Response.ErrorMessage === null
    ) {
      return this.props.Popups.filter((x) => x.ShowWhenCorrect)[0];
    }

    const selectedPopup = this.props.Popups.filter(
      (x) =>
        this.props.Response.ErrorMessage.indexOf(x.ShowWhenErrorContains) > 0 &&
        !x.IsDefault &&
        !x.ShowWhenCorrect
    );

    if (selectedPopup.length !== 1) {
      // console.log(`found more than one or not found popup for response Error: ${this.props.Response.ErrorMessage}`);
      return this.props.Popups.filter((x) => x.IsDefault)[0];
    }

    return selectedPopup[0];
  }

  renderPopup(popup: IPopup): JSX.Element {
    return (
      <div className={popup.Classes.Container}>
        <div className={popup.Classes.Box} onClick={this.close}>
          {
            (
              (this.props.FormCode === "Wella_1PD" && this.props.Response.StatusCode !== 0) 
              || (this.props.FormCode !== "Wella_1PD")
            ) 
              && <div className={popup.Classes.Button} onClick={this.close}>
            X
          </div>}
          <div className={popup.Classes.Label}>{popup.Label}</div>
          {this.props.FormCode === "Wella_1PD" &&
          this.props.Response.StatusCode === 0 ? (
            <>
              <div className="popup-info">
                Press the button below to access your free content <br />
                and stay tuned for the latest news arriving soon to your inbox!
              </div>
              {this.props.PopupUrl && (
                <div
                  onClick={(e) => this.getEducationBook(e)}
                  className="popup-download"
                >
                  DOWNLOAD PDF
                </div>
              )}
            </>
          ) : null}
        </div>
      </div>
    );
  }

  render(): JSX.Element {
    if (this.state.isVisible) {
      return this.renderPopup(this.selectPopup());
    }
    return <div />;
  }
}

export default Popup;
