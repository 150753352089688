// configuration
import { EFieldName } from "../../configurations/EFieldType";
import { configuration } from "../../configurations/FieldToComponentMapperConfiguration";

// types
import IFieldConfig from "../../types/IFieldConfig";

export default function getFieldConfiguration(type: EFieldName): IFieldConfig {
  const occurence = configuration.filter((x) => x.Type.valueOf() === type);
  throwErrorIfNotExists(type, occurence);
  return occurence[0];
}

function throwErrorIfNotExists(type: EFieldName, occurence: Array<any>) {
  if (occurence.length !== 1) {
    throw new Error(
      `Can't find or to many configuration for type: ${type.toString()}`
    );
  }
}
