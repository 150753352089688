import parseFormConfig from "./parseFormConfig";

export default function getLanguage(): string {
  const formConfig = parseFormConfig();
  if (formConfig.FormLang === undefined) {
    const urllang: string = window.location.pathname;
    // console.log("ulrLang", urllang.slice(1, 6), window.location.pathname);
    return urllang.slice(1, 6);
  }
  return formConfig.FormLang;
}
