export default function getMonth(translations: any) {
  const months: string[] = [];
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  month.forEach((data) => {
    const result = translations.find((x: any) => x.Name === data).Label;
    months.push(result);
  });
  return months;
}
