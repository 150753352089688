import * as React from "react";

// components
import IField from "../types/IField";
import { EInputType } from "../configurations/EInputType";
import Error from "./Error/Error";
import Label from "./Label/Label";
import RenderComponent from "./RenderComponent";

// types

interface iProps {
  field: IField;
  onChange: (evt: any) => void;
}

class Input extends React.Component<iProps, any> {
  getExtraCheckboxClass = (): string =>
    this.props.field.InputType === EInputType.checkbox &&
    this.props.field.Value === "true"
      ? "checked"
      : "";

  getInvalidClass = (): string =>
    this.props.field.Validation.Invalid || this.props.field.Validation.Missing
      ? "invalid"
      : "";

  render(): JSX.Element {
    const { field, onChange } = this.props;
    const { Invalid, Missing } = field.Validation;
    const { InvalidErrorMessage, MissingErrorMessage } = field.ErrorMessages;
    let message = "";

    if (Invalid) {
      message = InvalidErrorMessage;
    } else if (Missing) {
      message = MissingErrorMessage;
    }

    return (
      <div
        className={`${
          field.Classes.Container
        } ${this.getExtraCheckboxClass()} ${this.getInvalidClass()}`}
      >
        <Label
          key={`Label-${field.Name}`}
          cssClassName={field.Classes.Label}
          htmlFor={field.Name}
          text={field.Label}
        />
        <RenderComponent
          key={`RenderFor-${field.Name}`}
          field={field}
          onChange={onChange}
        />
        <Error
          key={`Error-${field.Name}`}
          message={message}
          cssClassName={field.Classes.Error}
        />
      </div>
    );
  }
}

export default Input;
