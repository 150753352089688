import { ChangeEvent, FC } from "react";

import IField from "../../types/IField";

export interface Props {
  field: IField;
  onChange: (evt: ChangeEvent) => void;
}

const CheckBox: FC<Props> = function ({ field, onChange }) {
  return (
    <input
      className={field.Classes.Input}
      name={field.Name}
      id={field.Name}
      type="checkbox"
      checked={field.Value === "true"}
      onChange={onChange}
    />
  );
};

export default CheckBox;
