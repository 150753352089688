import React, { FC } from "react";

import IField from "../../types/IField";

export interface Props {
  field: IField;
  onChange: (evt: any) => void;
}

const RadioBox: FC<Props> = function ({ field, onChange }) {
  return field.Options ? (
    <div>
      {field.Options.map((option) => (
        <React.Fragment key={`${option.Name}`}>
          <input
            id={option.Name}
            type="radio"
            name={field.Name}
            value={option.Label}
            onChange={onChange}
          />

          <label htmlFor={option.Name}>
            <span />
            {option.Label}
          </label>
        </React.Fragment>
      ))}
    </div>
  ) : null;
};

export default RadioBox;
