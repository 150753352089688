import { FC, useEffect, useState } from "react";

import IField from "../../types/IField";

export interface Props {
  cssClassName: string;
  message: string;
}

const Error: FC<Props> = function ({ message, cssClassName }) {
  return message !== "" ? (
    <span role="alert" className={cssClassName}>
      {message}
    </span>
  ) : null;
};

export default Error;
