import * as React from "react";
import ReCAPTCHA from "react-google-recaptcha";

// types
import IField from "../types/IField";
import IButton from "../types/IButton";
import IFormConfig from "../types/IFormConfig";
import IApiResponse from "../types/IApiResponse";
import ITranslation from "../types/ITranslation";
import { EInputType } from "../configurations/EInputType";
import IAggrementsData from "../types/IAggrementsData";

// components
import getSalons from "../services/getSalons";
import getLanguage from "../core/infrastructure/getLanguage";
import { getLanguageToCaptcha } from "../core/infrastructure/getLanguageToCaptcha";

// logic
import {
  submit,
  validateAll,
  onFieldChange,
  getVisibleFields,
  everyFieldIsValid,
} from "../core/logic/FormLogic";

// mappers
import { getFields, getButton, getPopups } from "../core/logic/Mappers";

// validators
import { validate } from "../core/logic/ErrorLogic";

// enums
import { EFieldName } from "../configurations/EFieldType";
import IPopup from "../types/IPopup";
import SalonBox from "./SalonBox/SalonBox";
import SubmitButton from "./SubmitButton/SubmitButton";
import Paragraph from "./Paragraph/Paragraph";
import Popup from "./Popup";
import Input from "./Input";
import Error from "./Error/Error";
import Captcha from "./Captcha/Captcha";

interface iProps {
  FormConfig: IFormConfig;
  Translations: Array<ITranslation>;
  Countries: Array<any>;
  Brands: Array<any>;
  Months: Array<any>;
  Days: Array<any>;
  Years: Array<any>;
  FormType: string;
  FormUrl: string;
  Provinces: Array<any>;
  AggrementsData: Array<IAggrementsData>;
}

interface iState {
  ReadyToRender: boolean;
  Fields: Array<IField>;
  Button: IButton;
  Popups: Array<IPopup>;
  ShowAdditionalFields: boolean;
  ShowSalonList: Array<[]>;
  SalonList: Array<[]>;
  FormType: string;
  Response: IApiResponse;
  Sending: boolean;
  SendSuccess: boolean;
  CountryCode: string;
  CaptchaPassed: boolean;
}

export default class Form extends React.Component<iProps, iState> {
  constructor(props: iProps) {
    super(props);
    this.state = {
      ...this.state,
      ShowAdditionalFields: false,
      ShowSalonList: [],
      SalonList: [],
      Sending: false,
      SendSuccess: false,
      CountryCode: "",
      CaptchaPassed: false,
    };
  }

  UNSAFE_componentWillMount() {
    const isAditional: any[] = this.props.FormConfig.Fields.map(
      (x) => x.IsAdditional !== undefined
    );
    this.setState({
      Fields: getFields(
        this.props.FormConfig.Fields,
        this.props.Translations,
        this.props.Countries,
        this.props.Brands,
        this.props.Months,
        this.props.Days,
        this.props.Years,
        this.props.Provinces
      ),
      Button: getButton(this.props.FormConfig.Button, this.props.Translations),
      Popups: getPopups(this.props.FormConfig.Popups, this.props.Translations),
      ReadyToRender: true,
      ShowAdditionalFields: isAditional.filter((x) => x === true).length > 0,
    });
  }

  findTranslationByName = (name: string): string => {
    let trans = "";
    this.props.Translations.forEach((translation) => {
      if (translation.Name.toLowerCase() === name.toLowerCase()) {
        trans = translation.Label;
      }
    });
    return trans;
  };

  renderProvince = (field: IField): JSX.Element => {
    const CountryCode =
      this.state.Fields.filter((x) => x.Name === "CountrySelector")[0].Value ===
      undefined
        ? ""
        : this.state.Fields.filter((x) => x.Name === "CountrySelector")[0]
            .Value;
    return (
      CountryCode === "CA" && (
        <Input
          id={field.Name}
          key={`Input-${field.Name}`}
          field={field}
          onChange={this.onChange}
        />
      )
    );
  };

  setCaptchaPassed = (item: boolean) => {
    this.setState(
      {
        CaptchaPassed: item,
      },
      () => {
        this.onChangeRecaptcha(item);
      }
    );
  };

  renderField = (field: IField): JSX.Element => {
    const { Invalid, Missing } = field.Validation;
    const { InvalidErrorMessage, MissingErrorMessage } = field.ErrorMessages;
    let message = "";

    if (Invalid) {
      message = InvalidErrorMessage;
    } else if (Missing) {
      message = MissingErrorMessage;
    }

    switch (field.InputType) {
      case EInputType.label:
        return (
          <Paragraph
            key={`Paragraph-${field.Name}`}
            cssClassName={field.Classes.Label}
            text={field.Label}
          />
        );
      case EInputType.captcha:
        return (
          <div
            key={`RecaptchaContainer-${field.Name}`}
            className={field.Classes.Container}
          >
            <div className={field.Classes.Input}>
              {/* <Captcha */}
              {/*  key={`Recaptcha-${field.Name}`} */}
              {/*  sitekey={field.SiteKey} */}
              {/*  onChange={this.onChangeRecaptcha} */}
              {/* /> */}
              <Captcha
                setFailedCaptcha={() => this.setCaptchaPassed(false)}
                setPassedCaptcha={(value: string) =>
                  this.onChangeRecaptcha(value)
                }
                sitekey={field.SiteKey}
              />
            </div>
            <Error
              key={`Error-${field.Name}`}
              message={message}
              cssClassName={field.Classes.Error}
            />
          </div>
        );
      default:
        if (field.Name !== "ProvinceSelector") {
          return (
            <Input
              key={`Input-${field.Name}`}
              field={field}
              onChange={this.onChange}
            />
          );
        }
        this.renderProvince(field);

      // return field.Name !== "ProvinceSelector" ? (
      //   <Input
      //     key={`Input-${field.Name}`}
      //     field={field}
      //     onChange={this.onChange}
      //   />
      // ) : field.Name === "ProvinceSelector" ? (
      //   this.renderProvince(field)
      // ) : null;
    }
  };

  onbuttonClick = () => {
    const Code = this.props.FormConfig.FormCode.split("_")[1];
    const fields = validateAll(this.state.Fields, this.props.AggrementsData);
    // let formCode = this.props.FormConfig.FormCode.toLowerCase().indexOf("newsletter");

    this.updateFields(fields);
    if (everyFieldIsValid(this.state.Fields, this.state.ShowAdditionalFields)) {
      this.setState({
        Sending: true,
      });
      submit(
        this.state.Fields,
        this.props.FormConfig.FormCode,
        this.props.FormUrl,
        this.state.ShowAdditionalFields,
        this.props.FormType,
        this.props.FormConfig.FormLang
      ).then((x) =>
        this.setState(
          {
            Response: x,
          } as iState,
          () => {
            if (this.state.Response.StatusCode === 0) {
              const event = new CustomEvent("signupComplete", {
                detail: {
                  eventInfo: {
                    eventAction: `${Code} Signup`,
                    eventName: "signupComplete",
                    eventType: "userEvent",
                    newsletterSignup: {
                      state: "complete",
                    },
                  },
                },
              });
              document.body.dispatchEvent(event);
              this.setState({
                Sending: false,
                SendSuccess: true,
              });
            } else {
              const event = new CustomEvent("signupComplete", {
                detail: {
                  eventInfo: {
                    eventAction: `${Code} Signup`,
                    eventName: "signupComplete",
                    eventType: "userEvent",
                    newsletterSignup: {
                      state: "error",
                    },
                  },
                },
              });
              document.body.dispatchEvent(event);
              this.setState({
                Sending: false,
                SendSuccess: false,
              });
            }
            // Trigger event.
            // document.body.dispatchEvent(event);
            // console.log(this.state.Response.StatusCode, "response");
          }
        )
      );
    }
  };

  // changeAdditionalFieldsVisibility = (field: IField) => {
  //   if (
  //     this.props.FormConfig.WhenToShowAdditionalFields.WhenComponentWithName ===
  //     field.Name
  //   ) {
  //     if (field.InputType === "radio") {
  //       // console.log("show!!!");
  //       this.setState({
  //         ShowAdditionalFields: true,
  //       } as iState);
  //     } else {
  //       this.setState({
  //         ShowAdditionalFields:
  //           this.props.FormConfig.WhenToShowAdditionalFields.HasValue ===
  //           field.Value,
  //       } as iState);
  //     }
  //   }
  // };

  resetProvince = (fields) => {
    const CountryCode =
      fields.filter((x: any) => x.Name === "CountrySelector")[0].Value ===
      undefined
        ? ""
        : fields.filter((x: any) => x.Name === "CountrySelector")[0].Value;
    if (CountryCode !== "CA") {
      const Province =
        fields.filter((x: any) => x.Name === "ProvinceSelector")[0].Value ===
        undefined
          ? ""
          : fields.filter((x: any) => x.Name === "ProvinceSelector")[0];
      Province.Value = "";
      return fields;
    }
  };

  onChange = (evt: any): void => {
    const fields = this.state.Fields;
    const field = onFieldChange(evt, fields, this.props.AggrementsData);
    this.checkSalonByCountry(field);
    fields.forEach((data) => {
      if (data.Name === "ProvinceSelector") {
        this.resetProvince(fields);
      }
    });
    // fields.map((data: IField) => {
    //   data.Name === "ProvinceSelector" ? this.resetProvince(fields) : null;
    // });
    // this.checkSalonByCountry(field);
    // this.changeAdditionalFieldsVisibility(field);
    this.updateFields(fields);
  };

  checkSalonByCountry = (value: any): void => {
    const formCode = this.props.FormConfig.FormCode.toLowerCase();
    if (value.Name === "CountrySelector" && formCode.indexOf("contact") > -1) {
      const countryCode = value.Value;
      this.setState({
        CountryCode: countryCode,
      });
    }
  };

  onChangeRecaptcha = (value: any): void => {
    const fields = this.state.Fields;
    let field = fields.filter((x) => x.Name === EFieldName.Captcha)[0];
    field.Value = value;
    // field.Validation.Invalid = false;
    // field.Validation.Missing = !value;
    field = validate(field, fields, this.props.AggrementsData);
    this.updateFields(fields);
  };

  checkFieldsByName = (fields: Array<IField>): void => {
    fields.forEach((fieldone) => {
      if (fieldone.Value === "Becomeacustomer") {
        fields.forEach((field) => {
          if (field.Name === "SalonNameReq") {
            field.Hidden = false;
          }
          if (field.Name === "SalonAddressReq") {
            field.Hidden = false;
          }
          if (field.Name === "SalonPhoneReq") {
            field.Hidden = false;
          }
          if (field.Name === "SalonManagerEmailReq") {
            field.Hidden = false;
          }
          if (field.Name === "StylistNumbPerSalonReq") {
            field.Hidden = false;
          }
          if (field.Name === "City") {
            field.Hidden = false;
          }
          if (field.Name === "PostalCode") {
            field.Hidden = false;
          }
        });
      } else if (
        fieldone.Value === "ProductandTreatment" ||
        fieldone.Value === "Aftersales" ||
        fieldone.Value === "Wheretoorder" ||
        fieldone.Value === "TrainingandSeminar" ||
        fieldone.Value === "Others" ||
        fieldone.Value === "CustomerComplaintHairdresser"
      ) {
        fields.forEach((field) => {
          if (field.Name === "SalonNameReq") {
            field.Hidden = true;
          }
          if (field.Name === "SalonAddressReq") {
            field.Hidden = true;
          }
          if (field.Name === "SalonPhoneReq") {
            field.Hidden = true;
          }
          if (field.Name === "SalonManagerEmailReq") {
            field.Hidden = true;
          }
          if (field.Name === "StylistNumbPerSalonReq") {
            field.Hidden = true;
          }
          if (field.Name === "ClientNumber") {
            field.Hidden = true;
          }
          if (field.Name === "ProductName") {
            field.Hidden = true;
          }
          if (field.Name === "BatchCode") {
            field.Hidden = true;
          }
          if (field.Name === "ProductEanCode") {
            field.Hidden = true;
          }
          if (field.Name === "Description") {
            field.Hidden = true;
          }
          if (field.Name === "BrandsSelector") {
            field.Hidden = true;
          }
        });
      }
      if (fieldone.Value === "CustomerComplaintHairdresser") {
        fields.forEach((field) => {
          if (field.Name === "ClientNumber") {
            field.Hidden = false;
          }
          if (field.Name === "ProductName") {
            field.Hidden = false;
          }
          if (field.Name === "BatchCode") {
            field.Hidden = false;
          }
          if (field.Name === "Description") {
            field.Hidden = false;
          }
          if (field.Name === "BrandsSelector") {
            field.Hidden = false;
          }
          if (field.Name === "ProductEanCode") {
            field.Hidden = false;
          }
        });
      } else if (
        fieldone.Value === "ProductandTreatment" ||
        fieldone.Value === "Aftersales" ||
        fieldone.Value === "Wheretoorder" ||
        fieldone.Value === "TrainingandSeminar" ||
        fieldone.Value === "Others" ||
        fieldone.Value === "Becomeacustomer"
      ) {
        fields.forEach((field) => {
          if (field.Name === "ClientNumber") {
            field.Hidden = true;
          }
          if (field.Name === "ProductName") {
            field.Hidden = true;
          }
          if (field.Name === "BatchCode") {
            field.Hidden = true;
          }
          if (field.Name === "Description") {
            field.Hidden = true;
          }
          if (field.Name === "BrandsSelector") {
            field.Hidden = true;
          }
          if (field.Name === "ProductEanCode") {
            field.Hidden = true;
          }
        });
      }
    });
  };

  updateFields = (fields: Array<IField>) => {
    this.checkFieldsByName(fields);
    this.setState({
      Fields: fields,
    } as iState);
  };

  render() {
    const { FormClasses } = this.props.FormConfig;
    return (
      <div
        className={
          !this.state.ShowSalonList
            ? "form-container-widget"
            : "form-container-widget salons"
        }
      >
        <form id="ContactForm" className={`${FormClasses}`} name="contactForm">
          {getVisibleFields(
            this.state.Fields,
            this.state.ShowAdditionalFields
          ).map((field) => this.renderField(field))}
        </form>
        <p className="mandatory">
          {this.findTranslationByName("MandatoryFields")}
        </p>
        {!this.state.SendSuccess ? (
          <SubmitButton
            key={`SubmitButton-${this.props.FormConfig.FormCode}`}
            button={this.state.Button}
            onClick={this.onbuttonClick}
            Sending={this.state.Sending}
          />
        ) : null}
        {this.props.FormConfig.FormCode.toLowerCase().indexOf("contact") > -1 &&
        this.props.FormConfig.FormCode.toLowerCase().indexOf("colordj") ===
          -1 ? (
          <SalonBox
            countryCode={this.state.CountryCode}
            formCode={this.props.FormConfig.FormCode}
            formConfig={this.props.FormConfig}
            lang={getLanguage()}
          />
        ) : null}
        <Popup
          Popups={this.state.Popups}
          Response={this.state.Response}
          FormLang={this.props.FormConfig.FormLang}
          FormCode={this.props.FormConfig.FormCode}
          PopupUrl={this.props.FormConfig.PopupUrl}
        />
      </div>
    );
  }
}
