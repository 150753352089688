enum EFieldName {
  Country = "Country",
  ImType = "ImType",
  ZContactTypeHair = "ZContactTypeHair",
  ZContactTypeNail = "ZContactTypeNail",
  EmailAddress = "EmailAddress",
  ConfirmEmailAddress = "ConfirmEmailAddress",
  FirstName = "FirstName",
  LastName = "LastName",
  Phone = "Phone",
  Subject = "Subject",
  PolicyAndTCAgrement = "PolicyAndTCAgrement",
  Im = "Im",
  Message = "Message",
  Newsletter = "Newsletter",
  Privacy = "Privacy",
  DEPrivacyPolicy = "DEPrivacyPolicy",
  DETermsAndConditions = "DETermsAndConditions",
  TextAboveAgr = "TextAboveAgr",
  TextAboveAdditionalInformation = "TextAboveAdditionalInformation",
  CountrySelector = "CountrySelector",
  ProvinceSelector = "ProvinceSelector",
  Month = "Month",
  Day = "Day",
  MonthSelector = "MonthSelector",
  SalonNameReq = "SalonNameReq",
  SalonAddressReq = "SalonAddressReq",
  SalonPhoneReq = "SalonPhoneReq",
  SalonManagerEmailReq = "SalonManagerEmailReq",
  StylistNumbPerSalonReq = "StylistNumbPerSalonReq",
  Year = "Year",
  AdditionalInformation = "AdditionalInformation",
  ProductInformation = "ProductInformation",
  UnsubBottomMsg = "UnsubBottomMsg",
  Otherhandles = "Otherhandles",
  Twitter = "Twitter",
  Pinterest = "Pinterest",
  Facebook = "Facebook",
  Instagramhandle = "Instagramhandle",
  PassQuestionNo1 = "PassQuestionNo1",
  PassQuestionNo2 = "PassQuestionNo2",
  PassionistasQ3 = "PassionistasQ3",
  PassionistasQ4 = "PassionistasQ4",
  SebastianInsidersNo1 = "SebastianInsidersNo1",
  SebastianInsidersNo2 = "SebastianInsidersNo2",
  SebastianInsidersQ3 = "SebastianInsidersQ3",
  SebastianInsidersQ4 = "SebastianInsidersQ4",
  Birthday = "Birthday",
  SubjectDJ = "SubjectDJ",
  PassionistasSubject = "PassionistasSubject",
  DoYouUseWellaInYourSalon = "DoYouUseWellaInYourSalon",
  TikTokhandle = "TikTokhandle",
  PersonalFacebook = "PersonalFacebook",
  Youtube = "Youtube",
  ProfessionalFacebook = "ProfessionalFacebook",
  HowDidYouHearAboutWellaPassionistas = "HowDidYouHearAboutWellaPassionistas",
  Adress = "Adress",
  Adress2 = "Adress2",
  AdressSPGain = "AdressSPGain",
  City = "City",
  ZipCode = "ZipCode",
  PostalCode = "PostalCode",
  CareBrand = "CareBrand",
  WhatPrice = "WhatPrice",
  NumberofStylists = "NumberofStylists",
  CountrySelectorSP = "CountrySelectorSP",
  ClientNumber = "ClientNumber",
  ProductName = "ProductName",
  BatchCode = "BatchCode",
  ProductEanCode = "ProductEanCode",
  Description = "Description",
  BrandsSelector = "BrandsSelector",
  FreelanceHairdresser = "FreelanceHairdresser",
  HairNailSalonOwner = "HairNailSalonOwner",
  InSalonHairdresser = "InSalonHairdresser",
  HairOnlySalonOwner = "HairOnlySalonOwner",
  ProfessionalType = "ProfessionalType",

  // button
  Submit = "Submit",

  // captcha
  Captcha = "Captcha",
}

export { EFieldName };
