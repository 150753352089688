// types
import IField from "../../types/IField";
import IApiResponse from "../../types/IApiResponse";
import IAggrementsData from "../../types/IAggrementsData";

// validators
import postSubmit from "../../services/postSubmit";
import { validate } from "./ErrorLogic";

// api

// mappers
import { prepareSubmitJson } from "./Mappers";

function validateAll(
  fields: Array<IField>,
  aggrementsData: Array<IAggrementsData>
): Array<IField> {
  return fields.map((field) => validate(field, fields, aggrementsData));
}

function everyFieldIsValid(
  fields: Array<IField>,
  showAdditionalFields: boolean
): boolean {
  let fieldsToValidate = fields.filter((x) => !x.Hidden);

  if (!showAdditionalFields) {
    fieldsToValidate = fieldsToValidate.filter((x) => !x.IsAdditional);
  }

  const valid = fieldsToValidate.filter(
    (field) =>
      field.Validation.Invalid === true || field.Validation.Missing === true
  );

  return valid.length === 0;
}

function getVisibleFields(
  fields: Array<IField>,
  showAdditionalFields: boolean
): Array<IField> {
  // console.log("visible", fields);
  let result = fields.filter((x) => !x.Hidden);
  // console.log("visible2", result);

  if (!showAdditionalFields) {
    // console.log("visible3", result);
    result = result.filter((x) => !x.IsAdditional);
  }
  // console.log("visible4", result);

  return result;
}

function onFieldChange(
  evt: any,
  fields: Array<IField>,
  aggrementsData: Array<IAggrementsData>
): IField {
  let field = fields.filter((x) => x.Name === evt.target.name)[0];
  // console.log(fields, "onFieldChange", field);

  if (evt.target.type === "checkbox") {
    evt.target.value = evt.target.value === "true" ? "false" : "true";
  }

  if (evt.target.name === "ImType" || evt.target.name === "ZContactTypeHair") {
    if (evt.target.value === "EndConsumer") {
      const fieldIm = fields.filter((x) => x.Name === "Im")[0];
      fieldIm.Value = "Consumer";
      validate(fieldIm, fields, aggrementsData);
    } else {
      const fieldIm = fields.filter((x) => x.Name === "Im")[0];
      fieldIm.Value = "Professional";
      validate(fieldIm, fields, aggrementsData);
    }
  }

  field.Value = evt.target.value;
  field = validate(field, fields, aggrementsData);

  return field;
}

function submit(
  fields: Array<IField>,
  formCode: string,
  formUrl: string,
  sendAdditinalFields: boolean,
  formType: string,
  formLang: string
): Promise<IApiResponse> {
  const submitData = prepareSubmitJson(
    fields,
    formCode,
    sendAdditinalFields,
    formLang
  );
  return postSubmit(submitData, formUrl, formType);
}

export {
  submit,
  validateAll,
  onFieldChange,
  getVisibleFields,
  everyFieldIsValid,
};
