import XRegExp from "xregexp";

export default function validate(value: string, min = 1, max = 200): boolean {
  const unicodeWord = XRegExp("^[\\p{Latin}\\p{Common}]+$");
  const unicodeWord2 = XRegExp.tag()`^\pL[\pL\pM]*$`;
  if (value.length > max || value.length < min) {
    return false;
  }
  if (unicodeWord.test(value)) {
    return true;
  }
  return unicodeWord2.test(value);

  // return /([A-Z][a-zA-Z]*)/.test(value);
}
