import IApiResponse from "../types/IApiResponse";
import "ts-polyfill";

function get(url: string): Promise<IApiResponse> {
  return fetch(url, {
    method: "GET",
    credentials: "same-origin",
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      apiErrorHandler(url, error);
    });
}

function post(url: string, body: any): Promise<IApiResponse> {
  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "same-origin",
    body: JSON.stringify(body),
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      apiErrorHandler(url, error);
    });
}

function apiErrorHandler(url: string, error: string) {
  console.error(`Error occured during following call: ${url}`, error);
}

export { get, post };
