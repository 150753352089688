import * as React from "react";

// components
import IField from "../types/IField";
import { EInputType } from "../configurations/EInputType";
import TextBox from "./TextBox/TextBox";
import TextArea from "./TextArea/TextArea";
import Checkbox from "./CheckBox/CheckBox";
import SelectBox from "./SelectBox/SelectBox";
import RadioBox from "./RadioBox/RadioBox";

// types

interface iProps {
  field: IField;
  onChange: (evt: any) => void;
}

class RenderComponent extends React.Component<iProps, any> {
  render(): JSX.Element {
    const { field, onChange } = this.props;
    // console.log(field);

    switch (field.InputType) {
      case EInputType.text:
        return (
          <TextBox
            key={`TextBox-${field.Name}`}
            field={field}
            onChange={onChange}
          />
        );
      case EInputType.select:
        return (
          <SelectBox
            key={`SelectBox-${field.Name}`}
            options={field.Options ? field.Options : []}
            cssClassName={field.Classes.Input}
            name={field.Name}
            value={field.Value}
            onChange={onChange}
          />
        );
      case EInputType.textarea:
        return (
          <TextArea
            key={`TextArea-${field.Name}`}
            field={field}
            onChange={onChange}
          />
        );
      case EInputType.checkbox:
        return (
          <Checkbox
            key={`Checkbox-${field.Name}`}
            field={field}
            onChange={onChange}
          />
        );
      case EInputType.radio:
        // console.log("radio", field)
        return (
          <RadioBox
            key={`Radiobox-${field.Name}`}
            field={field}
            onChange={onChange}
          />
        );
      default:
    }
  }
}

export default RenderComponent;
